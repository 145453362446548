/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { useMediaQuery } from '@mui/material';

import Drawer from 'components/uiLibrary/Drawer';

import usePageContext from 'utils/hooks/usePageContext';
import ImpressionTracker from 'utils/components/impressionTracker';
import { ENTITY_TYPE } from 'constants/index';

import useTracking from 'components/Globals/Analytics';
import LocationAndDates from './LocationAndDates';
import Producers from './Producers';
import CastCrew from './CastCrew';
import ProductionTags from './ProductionTags';
import MusicalWorkInfo from './MusicalWorkInfo';
import ProductionPeriod from './ProductionPeriod';
import DrawerHeader from '../QuickPreview/DrawerHeader';
import { QuickPreviewContent } from '../QuickPreview';

import classes from './ProSlug.module.scss';

const ICONS = {
  COLLAPSE: 'expand_less',
  EXPAND: 'expand_more',
  RIGHT: 'chevron_right',
};

const Icon = ({ icon }) => <SpriteIcon icon={icon} className={classes.activeIcon} />;

const ProSlug = ({
  showWorkDetails = false,
  production,
  profile,
  openInNewTab,
  shouldSectionExpand = true,
  isGraph = false, // TODO: Rename to isProArtistView
  redirectToProductionPage = false,
  isDefaultExpanded = false,
  highlightWorkId,
  trackingData,
}) => {
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
  const { navigate } = usePageContext();
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useMediaQuery('(max-width: 800px)');
  const track = useTracking();

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const completeTrackingData = {
    ...trackingData,
    entityId: production?.id,
    entityName: production?.name,
    entityType: ENTITY_TYPE.PRODUCTION,
  };

  const onClickHandler = event => {
    if (redirectToProductionPage) {
      event.preventDefault();
      event.stopPropagation();
      navigate.to(navigate.getLinkProps({ entity: production, entityType: ENTITY_TYPE.PRODUCTION }));
    }
    if (shouldSectionExpand) {
      setIsExpanded(!isExpanded);
    }
    track.click({
      ...completeTrackingData,
      meta: {
        isOpen: !isExpanded,
      },
    });
  };

  return (
    <ImpressionTracker data={completeTrackingData}>
      <div
        className={classnames(classes.parent, { [classes.active]: !!isExpanded })}
        onClick={onClickHandler}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        {shouldSectionExpand && (
          <LinkButton
            styles={{ root: classnames(classes.arrowIcon, { [classes.expandedLine]: !!isExpanded }) }}
            variant="text"
            leftIcon={
              <Icon {...(isMobile ? { icon: ICONS.RIGHT } : { icon: isExpanded ? ICONS.COLLAPSE : ICONS.EXPAND })} />
            }
            disableHover
            disableUnderline
            skipTracking
          />
        )}
        <div className={classes.components}>
          <div className={classes.leftBlock}>
            {showWorkDetails && (
              <MusicalWorkInfo
                isExpanded={isExpanded}
                showArtistPerformedDates={showWorkDetails}
                production={production}
                profile={profile}
                isHovered={isHovered || isExpanded}
                isGraph={isGraph}
                highlightWorkId={highlightWorkId}
                trackingData={trackingData}
              />
            )}
            {!showWorkDetails && !isMobile && (
              <LocationAndDates
                isExpanded={isExpanded}
                production={production}
                profile={profile}
                isHovered={isHovered}
                trackingData={trackingData}
              />
            )}
            {!showWorkDetails && isMobile && (
              <ProductionPeriod
                showArtistPerformedDates={showWorkDetails}
                production={production}
                isHovered={isHovered}
                profile={profile}
                trackingData={trackingData}
              />
            )}
          </div>
          <div className={classes.rightBlock}>
            <Producers
              isExpanded={isExpanded && !isMobile}
              showLocation={showWorkDetails}
              production={production}
              profile={profile}
              isHovered={isHovered}
              trackingData={trackingData}
            />
            <CastCrew
              isExpanded={isExpanded && !isMobile}
              production={production}
              hasWorkDetails={showWorkDetails}
              isGraph={isGraph}
              profile={profile}
              trackingData={trackingData}
            />
            {!isMobile && (
              <ProductionTags isExpanded={isExpanded} production={production} hasWorkDetails={showWorkDetails} />
            )}
          </div>
        </div>
        {!shouldSectionExpand && redirectToProductionPage && (
          <LinkButton
            styles={{ root: classnames(classes.arrowIcon, classes.arrowIcon_right) }}
            variant="text"
            onClick={onClickHandler}
            leftIcon={<Icon icon={ICONS.RIGHT} />}
            disableHover
            disableUnderline
            skipTracking
          />
        )}
      </div>
      {isExpanded && isMobile && (
        <Drawer
          className={classes.quickPreview}
          header={<DrawerHeader production={production} />}
          isOpen={isExpanded}
          onClose={() => setIsExpanded(false)}
        >
          <QuickPreviewContent production={production} profile={profile} openInNewTab={openInNewTab} />
        </Drawer>
      )}
    </ImpressionTracker>
  );
};

export default ProSlug;
