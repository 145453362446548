import React from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import EntityName from 'components/Globals/EntityName';
import usePageContext from 'utils/hooks/usePageContext';
import { useProducerDetails, getProductionEnsembles } from 'utils/productions';
import { getCityCountry } from 'utils/index';
import { ENTITY_TYPE } from 'constants/index';

import classes from './Producers.module.scss';

const ExpandedSection = ({ coProducers, production, trackingData }) => {
  const { ensembles } = getProductionEnsembles(production);
  const { navigate } = usePageContext();

  if (coProducers?.length === 0 && ensembles?.length === 0) {
    return null;
  }

  return (
    <div className={classes.expandedSection}>
      {coProducers?.map((coProducer, i) => (
        <div className={classes.expandedSection__coPartner} key={i}>
          <Typography color="secondary" size="12">
            CP
          </Typography>
          <LinkButton
            styles={{ root: classes.expandedSection__coPartner__name }}
            variant="text"
            isLink
            as={coProducer?.as}
            href={coProducer?.href}
            target="_blank"
            trackingData={{
              ...trackingData,
              entityId: coProducer?.id,
              entityName: coProducer?.value,
              entityType: coProducer?.entityType,
            }}
          >
            <EntityName
              entityType={coProducer?.entityType}
              entity={{ id: coProducer?.id, name: coProducer?.value }}
              isRaw
              trackingData={trackingData}
            />
          </LinkButton>
        </div>
      ))}
      {ensembles?.map((item, i) => {
        const { organization } = item;
        const entityType = organization?.id ? ENTITY_TYPE.ORGANIZATION : ENTITY_TYPE.ARTIST;
        const linkProps = navigate.getLinkProps({
          entityType,
          onlyLinkProps: true,
          entity: organization,
        });

        return (
          <div className={classes.expandedSection__ensemble} key={i}>
            <Typography color="secondary" size="12" className={classes.type}>
              {organization?.organizationType?.name}
            </Typography>
            <Typography className={classes.name}>
              <LinkButton
                styles={{ root: classes.name }}
                variant="text"
                {...(linkProps?.href && { isLink: true })}
                {...linkProps}
                target="_blank"
              >
                <EntityName
                  entityType={entityType}
                  entity={{ id: organization?.id, name: organization?.name }}
                  isRaw
                  trackingData={trackingData}
                />
              </LinkButton>
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

const Producers = ({ isExpanded = false, showLocation, production, isHovered, trackingData }) => {
  const { producers, coProducers } = useProducerDetails(production);
  const primaryProducer = producers?.[0]?.data?.[0];
  const cityCountry = getCityCountry(primaryProducer?.city, primaryProducer?.country);
  const isProductionArchived = production?.isArchived;

  const handleOnClick = e => {
    e.stopPropagation();
  };

  return (
    <div className={classes.parent}>
      <div
        className={classnames(classes.collapsedSection, {
          [classes.collapsedSection__multiRole]: showLocation,
        })}
      >
        <LinkButton
          styles={{ root: classnames(classes.link, { [classes.hover]: !!isExpanded }) }}
          variant="text"
          disableUnderline
          {...(isExpanded &&
            !isProductionArchived && {
              isLink: true,
              onClick: handleOnClick,
              as: primaryProducer?.as,
              href: primaryProducer?.href,
            })}
          target="_blank"
          trackingData={{
            ...trackingData,
            entityId: primaryProducer?.id,
            entityName: primaryProducer?.value,
            entityType: primaryProducer?.entityType,
          }}
        >
          <Typography weight="bold" size="12" className={classes.primaryProducer} blur={isProductionArchived}>
            <EntityName
              entityType={primaryProducer?.entityType}
              entity={{ id: primaryProducer?.id, name: primaryProducer?.value }}
              isRaw
              trackingData={trackingData}
            />
          </Typography>
        </LinkButton>
        {showLocation && cityCountry && (
          <Typography
            className={classes.venue}
            color={isExpanded || isHovered ? 'primary' : 'tertiary'}
            size="12"
            variant="p"
            truncate
            blur={isProductionArchived}
          >
            {cityCountry}
          </Typography>
        )}
      </div>
      {isExpanded && (
        <ExpandedSection production={production} coProducers={coProducers?.[0]?.data} trackingData={trackingData} />
      )}
    </div>
  );
};

export default Producers;
