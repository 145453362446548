import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';

import Typography from 'components/uiLibrary/Typography';
import EntityName from 'components/Globals/EntityName';

import { useTranslation } from 'src/i18n';
import { ENTITY_TYPE, TP, TRACK_SLUG_IMPRESSION } from 'constants/index';

import LocationAndDates from '../LocationAndDates';
import { getProgramLabel } from 'utils/common';
import ProductionPeriod from '../ProductionPeriod';

import classes from './MusicalWorkInfo.module.scss';

const MusicalWorkInfo = ({
  isExpanded,
  showArtistPerformedDates,
  production,
  profile,
  isHovered,
  isGraph,
  highlightWorkId,
  trackingData,
}) => {
  const { t } = useTranslation('NS_CASTING_TOOL');
  const isMobile = useMediaQuery('(max-width: 800px)');

  const { artist, musicalWork } = useMemo(() => {
    const productionWork = highlightWorkId
      ? production?.productionWorks?.find(item => item?.work?.id === highlightWorkId)
      : production?.productionWorks?.[0];
    const work = productionWork?.work;

    return {
      artist: production?.contributions?.find(item => item?.profile?.id === profile?.id),
      musicalWork: work,
    };
  }, [production, profile, highlightWorkId]);

  return (
    <div className={classes.parent}>
      <div className={classes.collapsedSection}>
        <div className={classnames(classes.musicalWork, { [classes.graphMWork]: !!isGraph })}>
          {artist?.credit && (
            <Typography weight="bold" size="12" className={classes.artistRole} truncate>
              {artist?.credit}
              {artist?.isCover && (
                <Typography color="tertiary" size="12">
                  ({t(`${TP}.FN_COVER`)})
                </Typography>
              )}
            </Typography>
          )}
          {production?.isProgramNotAnnounced && (
            <Typography size="12">{t(getProgramLabel(production, production?.productionWorks?.length))}</Typography>
          )}
          <EntityName
            entity={musicalWork}
            entityType={ENTITY_TYPE.WORK}
            typographyProps={{
              work: {
                size: '12',
                weight: 'regular',
              },
            }}
            eventType={TRACK_SLUG_IMPRESSION(ENTITY_TYPE.WORK)}
            trackingData={trackingData}
            withComposers
            onlyShortName
          />
        </div>
        <ProductionPeriod
          showArtistPerformedDates={showArtistPerformedDates}
          production={production}
          isHovered={isHovered}
          profile={profile}
          trackingData={trackingData}
        />
      </div>
      {!isMobile && (
        <LocationAndDates
          showCollapsedAlways={false}
          isExpanded={isExpanded}
          production={production}
          profile={profile}
          trackingData={trackingData}
        />
      )}
    </div>
  );
};

export default MusicalWorkInfo;
